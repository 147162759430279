.userAvatar {
    width: 50px;
    height: 50px;
}

.searchbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchbar input {
    border: 0;
    border-bottom: 1px solid #898989;
    background: none;
    color: #fff;
    font-size: 18px;
    outline: none;
    border-radius: 0;
}

.searchbar > span > span svg {
    color: #898989;
    outline: none;
    cursor: pointer;
}

.translation_dashboard {
    background: #181a1d;
    width: 100%;
    height: fit-content;
}

.row_1 {
    margin-bottom: 9px;
}

.header_name {
    margin-left: -13px;
}

.dropdown {
    z-index: 2;
    margin-left: 20px;
}

.dropbtn {
    color: #FAC01E;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: #252525;
    text-align: center;
    min-width: 130px;
    min-height: 40px;
    border-radius: 10px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 15px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropbtn img {
    width: 20px;
    color: white;
    height: auto;
    margin-left: 15px;
    margin-bottom: -5px;
}

.export_section {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 140px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    /* margin-left: 20px; */
}

.export_section .Checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(255, 139, 0);
    border-color: rgb(255, 139, 0);
    color: #fff;
}

.editable_class.variable_name {
    text-align: center;
}

.export_section a {
    color: black;
    padding: 15px 16px;
    text-decoration: none;
    display: block;
}

.dropdown:hover .export_section {
    display: block;
}

.export_section a:hover {
    background-color: #ddd;
}

.dropdown:hover .export_section {
    display: block;
}

.react-confirm-alert-overlay {
    background: rgba(48, 46, 46, 0.9) !important;
}

/*.save_button {*/
/*    color: white !important;*/
/*    margin: -8px 0 0 15px !important;*/
/*    padding: 13px 22px;*/
/*    border-radius: 4px;*/
/*    padding-bottom: 28px;*/
/*    display: inline-block;*/
/*    background: #08af5e;*/
/*}*/

/*.save_button:hover {*/
/*    background: #077942;*/
/*}*/

.all_rows {
    height: 500px;
    overflow-y: visible;
    overflow-x: hidden;
}

.translation_dashboard thead > tr > th {
    text-align: center;
    color: #898989;
    font-weight: normal;
    font-size: 14px;
    border-bottom: 1px solid #0e0f11;
    cursor: pointer;
    background: #181a1d;
}

.translation_dashboard thead > tr > th:hover,
.translation_dashboard thead > tr > th:active,
.translation_dashboard thead > tr > th:focus,
.translation_dashboard .ant-table-thead > tr > th.ant-table-column-sort {
    background: none !important;
}

.translation_dashboard thead tr .ant-table-column-sorter-down.on,
.translation_dashboard thead tr .ant-table-column-sorter-up.on {
    color: #FAC01E !important;
}

.translation_dashboard thead > tr > th > span > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.translation_dashboard thead > tr > th > span > div > span:nth-child(2) {
    margin-left: 10px;
}

.editable_language {
    float: right;
    margin-left: 50px;
}

.header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.header_left {
    display: flex;
}

.translation_dashboard .card {
    margin-bottom: 0;
}

.translation_dashboard .card > div > div > div > div > div > div > div {
    overflow-x: auto !important;
    outline: none !important;
}

.translation_dashboard .card table {
    width: 100%;
    border: 0 solid #e8e8e8 !important;
}

.translation_dashboard .card table thead th {
    border-right: 0 !important;
}

.translation_dashboard .card table tbody tr:hover td {
    background-color: #0e0f11 !important;
    cursor: pointer;
}

.translation_dashboard .card table tbody tr td {
    border-right: 0 !important;
    border-bottom: 1px solid #0e0f11 !important;
    color: rgb(175, 168, 168);
    text-align: center;
    word-break: break-word;
}

.translation_dashboard .card table,
.translation_dashboard .card table thead,
.translation_dashboard .card table thead tr,
.translation_dashboard .card table thead th {
    outline: none !important;
}

.editable_class {
    color: black;
    width: 10%;
}

.visible_class {
    color: rgb(175, 168, 168);
    width: 10%;
}

.non_editable_class {
    display: none;
}

.total_records {
    bottom: 20px;
    position: absolute;
    /* right: 560px; */
    left: 10px;
}

.translation_dashboard .card-header {
    background-color: #f2f4f8;
    margin-left: 15px;
}

.translation_dashboard .header_card {
    /* background-color: #f2f4f8; */
    /* margin-top: 15px; */
    padding: 10px 20px;
}

.select_project {
    margin-left: 5px;
}

.Checkbox {
    height: 40px;
    padding: 10px 20px;
    /* width: 80px; */
    white-space: nowrap;
}

.export_section .Checkbox:last-child {
    margin-bottom: 10px;
}

.Checkbox:hover {
    background-color: #cccccc9c;
    cursor: pointer;
}

/* @media only screen and (max-width:1400px)and (min-width:1131px) {
    .header {
      display:flex;
      flex-direction: row;
      align-items: baseline;
    }
    .header_left {
      display: flex;
      flex-direction: row;
      align-items: baseline;
    }
    .comdex_logo {
      margin-top:-10px;
    }
    .title_top {
      padding-top:10px;
    }
    .select_project {
      margin-left: 23px;
      font-size: 12px;
  }
  .select_input {
    width:200px;
    margin:5px 20px 20px 20px;
  }
  .dropbtn {
    font-size:12px;
  }
  .heading {
    margin-top: 10px;
    font-size: 18px;
  }
  }
  
  @media only screen and (max-width:1130px) and (min-width:417px) {
    .heading {
      margin-top: 10px;
    }
  }
  
  @media only screen and (max-width:1130px) and (min-width:320px) {
    .header {
      display:flex;
      flex-direction: column;
      align-items: baseline;
    }
    .header_left {
      display: flex;
      flex-direction: column;
      align-items: baseline;
    }
    .searchbar {
      margin: 0px 0 10px 19px;
    }
    .comdex_logo {
      margin-top:-10px;
    }
    .title_top {
      padding-top:10px;
    }
    .select_project {
      margin-left: 23px;
      font-size: 13px;
  }
  .select_input {
    width:200px;
    margin:5px 20px 20px 20px;
  }
  .search_input{
    width:200px;
  
  }
  .dropbtn {
    font-size:12px;
  }
  .heading {
   
    font-size: 18px;
  }
  
  } */
