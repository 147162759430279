html, body, #root, .ff_studio, .main_content, .router {
    height: 100%;
    overflow: hidden;
}

html, button, .ff_studio, p {
    font-family: 'Open Sans', sans-serif;
}

.loader_css {
    color: #525354 !important;
}

.loader_div {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #525354 !important;
    font-size: 20px;
}

.no_data_table {
    color: #fff;
    opacity: 0.5;
}

.ff_studio, p {
    margin: 0;
}

.ff_studio .container {
    background: #0e0f11;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 80%;
}

.ff_studio a, .ff_studio a:hover {
    text-decoration: none;
}

.data_table > div,
.ff_studio .gray_bg,
.ff_studio .gray_bg:hover {
    background-color: #181A1D;
}

.main_content {
    display: flex;
    overflow: hidden;
}

.ff_studio .top_nav {
    background: #232323;
    height: 60px;
    z-index: 100;
    display: flex;
    align-items: center;
    padding: 0 40px;
    justify-content: flex-end;
}

.ff_studio .top_nav img {
    width: 20px;
    height: 20px;
}

.ff_studio .content {
    z-index: 10;
    flex: 1;
    overflow: hidden;
}

.scroll_bar {
    overflow-y: scroll;
    height: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
    box-sizing: border-box;
    padding: 40px;
}

.table_scroll_bar {
    overflow: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
    box-sizing: border-box;
}

.table_scroll_bar > div {
    width: 100%;
    height: max-content;
}

.content .scroll_bar {
    overflow-x: hidden;
}

.content .scroll {
    overflow-y: auto;
}

html::-webkit-scrollbar,
textarea::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar,
.scroll::-webkit-scrollbar,
.table_scroll_bar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    border-radius: 4px;
}

html::-webkit-scrollbar-thumb,
textarea::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb,
.table_scroll_bar::-webkit-scrollbar-thumb {
    border: 0 solid #fff;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, .24);
    border-radius: 20px;
    background: #fff;
}

/*table css*/
.ff_studio .data_table {
    width: 100%;
    margin-bottom: 40px;
}

.ff_studio .data_table > div:first-child table thead tr th > span {
    display: flex;
    align-items: center;
}

.ff_studio .data_table > div:first-child table thead tr th > span > div {
    color: #626060;
}


.ff_studio .data_table > div:first-child table thead tr th > span > div > span svg {
    color: #626060 !important;
    font-size: 17px;
}

/* quick view css */
.ff_studio .row_action {
    display: flex;
    justify-content: center;
}

.ff_studio .row_action_icon svg {
    width: 14px;
    fill: #ffff;
}

.ff_studio .row_action_icon:hover svg {
    fill: #FAC01E;
}

.row_action_icon {
    position: relative;
}

.row_action_icon .icon {
    position: absolute;
}

.row_action_icon .progress_circle {
    width: 30px !important;
    height: 30px !important;
    color: #FAC01E;
}

.row_action_icon .progress_circle svg {
    width: 30px;
}

.row_action_icon .percentage_count {
    position: absolute;
    font-size: 10px;
    color: #FAC01E;
}

.ff_studio .row_action_icon:hover:after {
    content: '';
    position: absolute;
    bottom: -40px;
    right: 0;
    background: #393939;
    color: #e5b11f;
    padding: 4px 6px;
    width: 100px;
    z-index: 1;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    text-transform: capitalize;
    border-radius: 5px;
}

.ff_studio .row_action_icon.mint_nft:hover:after {
    content: 'Mint NFT';
}

.ff_studio .row_action_icon.download:hover:after {
    content: 'Download';
}

.ff_studio .row_action_icon.preview:hover:after {
    content: 'Preview';
}

.ff_studio .row_action_icon.edit:hover:after {
    content: 'Edit';
}

.ff_studio .row_action_icon.delete:hover:after {
    content: 'Delete';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 0 solid #ffffff9e;
    -webkit-text-fill-color: #ffffff9e;
    -webkit-box-shadow: 0 0 0 1000px #181a1d inset;
    transition: background-color 5000s ease-in-out 0s;
}

/* custom css */
.ff_studio .button_live {
    background-color: #252525;
    border-radius: 3px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #858585;
    border: 0;
    cursor: pointer;
    text-transform: capitalize;
}

.button_live:focus {
    outline: none;
}

.ff_studio .save_button,
.ff_studio .save_button:hover {
    border-radius: 10px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FAC01E;
    height: 40px;
    min-width: 100px;
}

.ff_studio .calendar_button,
.ff_studio .calendar_button:hover {
    text-align: center;
    color: #66615B;
    min-width: 130px;
    min-height: 40px;
    border-radius: 10px;
    text-transform: capitalize;
}

.ff_studio .calendar_button svg {
    width: 17px;
}

.ff_studio .link_url {
    color: rgb(255, 139, 0);
    padding: 0;
}

.ff_studio .card_div {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ff_studio .card_div .card_login .fields_section .text_field  > p {
    display: none;
}

.ff_studio .outline_button,
.dialog .outline_button {
    color: #FAC01E;
    border: 1px solid #FAC01E;
    box-sizing: border-box;
    border-radius: 5px;
}

.ff_studio .filled_button,
.dialog .filled_button {
    background: #FAC01E;
    color: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
}

.ff_studio .filled_button:hover {
    background: #FAC01E;
}

.ff_studio .delete_button {
    color: red;
    border: 1px solid red;
    box-sizing: border-box;
    border-radius: 5px;
}

.ff_studio .secondary_outline_button {
    color: #66615B;
    border: 1px solid #66615B;
    box-sizing: border-box;
    border-radius: 5px;
}

@media (max-width: 1050px) {
    .ff_studio .calendar_button {
        width: 100px;
        min-width: 80px;
    }

    .ff_studio .calendar_button > span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ff_studio .calendar_button > span > span {
        margin-left: 0 !important;
    }
}

@media (max-width: 1250px) and (min-width: 1051px) {
    .ff_studio .calendar_button {
        width: 100px;
        min-width: 80px;
    }

    .ff_studio .calendar_button > span {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .ff_studio .calendar_button > span > span {
        margin-left: 0 !important;
    }
}
