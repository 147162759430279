.permissions_checkbox {
    background: #181a1d !important;
    color: #fff !important;
    padding: 20px;
}

.uniquenames_section {
    display: flex;
    justify-content: flex-start;
}

.uniquenames_card_header {
    width: 30%;
}

.urls_sections {
    display: flex;
}

.permissions_section {
    width: 100%;
}

.uniquenames_card_header h2 {
    margin: 10px 0;
    font-size: 20px;
    font-weight: 400;
}

.urls_sections svg {
    color: #fff;
    font-weight: 400;
}
